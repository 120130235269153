<template>
  <div id="page-wrapper">
    <Breadcrumbs :crumbs="pageCrumbs" />
    <PageContent />
  </div>
</template>

<script>
import { mapState, useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { clientWorkWindowEvent } from '@/helpers/event-dispatch-helpers';
import { getMetaTags, getTitlePropByMetadata } from '@/helpers/metadata';
import PageContent from '@/components/PageContent.vue';
import Breadcrumbs from '@/components/global/Breadcrumbs.vue';
import { useAnchorTag } from '@/composables/useAnchorTag';

export default {
  name: 'Page',
  components: {
    Breadcrumbs,
    PageContent,
  },
  setup() {
    definePageMeta({
      middleware: ['page-content'],
    });

    const store = useStore();
    const { scrollToAnchor } = useAnchorTag();
    const route = useRoute();
    const metainfo = {
      metaTitle: getTitlePropByMetadata(store.state.page.metadata, store.state.page.name),
      metaTags: getMetaTags(store.state.page.metadata),
    };

    useHead({
      ...metainfo.metaTitle,
      meta: metainfo.metaTags,
    });

    onMounted(() => {
      scrollToAnchor(route.hash);
    });
  },
  data() {
    return {
      pageCrumbs: [],
      metadata: {},
    };
  },
  computed: {
    ...mapState(['page', 'globals', 'pathPrefix']),
  },
  mounted() {
    this.getBreadcrumbs();
    clientWorkWindowEvent('pageLoaded');
  },
  methods: {
    getBreadcrumbs() {
      this.pageCrumbs.push({
        name: this.globals.info.school_name,
        link: `${this.pathPrefix}/`,
      });
      if (!this.page.breadcrumbs) {
        return;
      }

      this.page.breadcrumbs.forEach((breadcrumb) => {
        const link = breadcrumb.slug ? `${this.pathPrefix}/page/${breadcrumb.slug}` : '';
        this.pageCrumbs.push({ name: breadcrumb.name, link });
      });
    },
  },
};
</script>
